<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
      <div class="form">
        <div class="form-row">
          <div class="form-col">
            <label for="categories">main topic</label>
            <Dropdown
              trackBy="intent_category_name"
              label="intent_category_name"
              :options="this.optionalData.categories"
              :value="this.optionalData.category"
              @input="setCategoryValue"
              objectMode
            />
            <span v-if="hasError('optionalData.category')" class="form-error">
              {{ showError('optionalData.category') }}
            </span>
            <label for="language" class="mt-4">language</label>
            <Dropdown
              trackBy="label"
              label="label"
              :options="[{label: 'english', value: 'en'}, {label: 'spanish', value: 'es'}]"
              :value="this.language"
              @input="setLanguage"
              :disabled="!this.intent_category_name.trim() && this.optionalData.intent ? this.optionalData.intent.intent_name !== 'new_conversation_detail' : false"
              objectMode
            />
            <span v-if="hasError('language')" class="form-error">
              {{ showError('language') }}
            </span>
          </div>
          <div class="form-col">
            <label for="categories">
              conversation detail
              <Tooltip icon="info" text="Select the option “new conversation detail“ in  order to create a new conversation detail" />
            </label>
            <Dropdown
              trackBy="intent_name"
              label="suggestion_text"
              :options="[{intent_name: 'new_conversation_detail', suggestion_text: 'new conversation detail'}, ...this.optionalData.intents]"
              :value="this.optionalData.intent"
              @input="setIntentValue"
              :disabled="!this.optionalData.category"
              objectMode
            />
            <span v-if="hasError('optionalData.intent')" class="form-error">
              {{ showError('optionalData.intent') }}
            </span>
            <div v-if="this.optionalData.intent ? this.optionalData.intent.intent_name === 'new_conversation_detail' : false">
              <label for="new_conversation_detail" class="mt-4">new conversation detail</label>
              <input type="text" class="form-input" id="new_conversation_detail" v-model="new_conversation_detail" :disabled="!this.language" />
              <span v-if="hasError('new_conversation_detail')" class="form-error">
                {{ showError('new_conversation_detail') }}
              </span>
            </div>
          </div>
          <div class="form-col">
            <label for="intent_category_name">intent category name</label>
            <input type="text" class="form-input" id="intent_category_name" v-model="intent_category_name" :disabled="this.optionalData.intent ? this.optionalData.intent.intent_name === 'new_conversation_detail' : false" />
          </div>
        </div>
      </div>
      <div
        class="form overflow-auto mt-8"
      >
      <div class="form-row">
        <div class="form-col">
          <label for="generated_intent_name">intent name</label>
            <input
              type="text"
              class="form-input"
              disabled
              :value="this.generated_intent_name"
            />
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>input context</label>
          <div class="container-element">
            <div class="mb-4" v-if="this.input_context.length > 0">
              <div class="element bordered flex items-center justify-between" v-for="(i_value, i) in this.input_context" :key="i">
                <span>{{i_value}}</span>
                <button
                  class="btn-action-warning focus:outline-none"
                  type="submit"
                  @click="() => this.deleteInput(i)"
                >
                  <icon class="w-5 h-5 mr-1" name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <input
              type="text"
              class="form-input"
              v-model="input_value"
            />
            <button :disabled="loading" class="btn-primary ml-2" type="submit" @click="addInput">add</button>
          </div>
        </div>
        <div class="form-col">
          <div class="flex">
            <div class="w-3/4 mr-1">
              <label>output context</label>
            </div>
            <div class="w-1/5">
              <label>span</label>
            </div>
          </div>
          <div class="flex mb-4" v-if="this.output_context.length > 0">
            <div class="w-3/4 mr-1">
              <div class="container-element">
                <div class="element bordered flex items-center justify-between" v-for="i in this.output_context" :key="i.name">
                  {{i.name}}
                  <button
                    class="btn-action-warning focus:outline-none"
                    type="submit"
                    @click="() => this.deleteOutput(i)"
                  >
                    <icon class="w-5 h-5 mr-1" name="trash"/>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-1/5">
              <div class="container-element">
                <div class="element bordered" v-for="i in this.output_context" :key="i.lifespan">{{i.lifespan}}</div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="w-2/3 mr-1">
              <input
                type="text"
                placeholder="output context"
                class="form-input"
                v-model="output_value"
              />
            </div>
            <div class="w-1/5">
              <input
                type="text"
                placeholder="span"
                class="form-input"
                v-model="span_value"
              />
            </div>
            <button :disabled="loading" type="submit" class="btn-primary ml-2" @click="addOutput">add</button>
          </div>
        </div>
        <div class="form-col">
          <label>intent description</label>
            <textarea type="text" class="form-input" id="text" v-model="text" />
            <span v-if="hasError('text')" class="form-error">
              {{ showError('text') }}
            </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>dialogflow failed webhook response</label>
          <div class="container-element">
            <div class="mb-4" v-if="this.text_response.length > 0">
              <div class="element bordered  flex items-center justify-between" v-for="(i_value, i) in this.text_response" :key="i">
                <span>{{i_value}}</span>
                <button
                  class="btn-action-warning focus:outline-none"
                  type="submit"
                  @click="() => this.deleteWebhook(i)"
                >
                  <icon class="w-5 h-5 mr-1" name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <input
              type="text"
              class="form-input"
              v-model="webhook_value"
            />
            <button :disabled="loading" class="btn-primary ml-2" @click="addWebhook">add</button>
          </div>
          <span v-if="this.array_errors.text_response" class="form-error">
            {{ this.array_errors.text_error }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>training phrases</label>
          <div class="container-element">
            <div class="mb-4" v-if="this.training_phrases.length > 0">
              <div class="element bordered  flex items-center justify-between" v-for="(i_value, i) in this.training_phrases" :key="i">
                <span>{{i_value}}</span>
                <button
                  class="btn-action-warning focus:outline-none"
                  type="submit"
                  @click="() => this.deleteTraining(i)"
                >
                  <icon class="w-5 h-5 mr-1" name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <input
              type="text"
              class="form-input"
               v-model="training_value"
            />
            <button :disabled="loading" class="btn-primary ml-2" @click="addTraining">add</button>
          </div>
          <span v-if="this.array_errors.training_phrases" class="form-error">
            {{ this.array_errors.training_error }}
          </span>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="save" />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import Loader from '@/components/ui/Loader'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'
import Icon from "@/components/ui/Icon";
import Dropdown from "@/components/ui/Dropdown";
import Tooltip from '@/components/ui/Tooltip';

export default {
  name: 'CreateResponse',
  components: { Loader, Dropdown, ModalFooter, Icon, Tooltip },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, ConversationMixin],
  data() {
    return {
      language: null,
      optionalData: {
        category: null,
        intent: null,
        categories: [],
        intents: [],
      },
      input_context: [],
      training_phrases: [],
      text_response: [],
      output_context: [],
      text: '',
      footer: {
        primaryButton: 'save',
      },
      input_value: '',
      training_value: '',
      webhook_value: '',
      output_value: '',
      span_value: '',
      intent_category_name: '',
      new_conversation_detail: '',
      array_errors: {
        text_response: false,
        text_error: '',
        training_phrases: false,
        training_error: ''
      }
    }
  },
  computed: {
    generated_intent_name () {
      let value = '';
      let category = this.optionalData.category;
      let intent = this.optionalData.intent;
      if(category) {
        value = `${category.intent_category_name}.`
      }
      if(intent) {
        if(intent.intent_name !== 'new_conversation_detail') {
          value = `${category.intent_category_name}.${intent.suggestion_text}.`;
        } else if(this.new_conversation_detail.trim()){
          value = `${category.intent_category_name}.${this.new_conversation_detail}.general`;
        }
      }
      if(this.intent_category_name.trim()) {
        value = `${category.intent_category_name}.${intent.suggestion_text}.${this.intent_category_name}`
      }

      return value.replace(/ /g,"_");
    }
  },
  methods: {
    fetchCategories() {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('categories')
        .then((res) => (this.optionalData.categories = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('intents', { category: categoryName })
        .then((res) => (this.optionalData.intents = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    setCategoryValue(value) {
      this.optionalData.intents = []
      this.optionalData.category = null
      this.optionalData.intent = null
      if (!value) return
      this.optionalData.category = value
      this.fetchIntents(value?.description)
    },
    setIntentValue(value) {
      this.optionalData.intent = null
      if (!value) return
      if(value.intent_name === 'new_conversation_detail') {
        this.intent_category_name = ''
      }
      this.optionalData.intent = value
    },
    setLanguage(value) {
      this.language = null;
      if(!value) return
      this.language = value;
    },
    addInput() {
      if(!this.input_value.trim()) return;
      this.input_context.push(this.input_value);
      this.input_value = '';
    },
    deleteInput(i) {
      this.input_context.splice(i, 1);
    },
    addTraining() {
      if(!this.training_value.trim()) return;
      this.training_phrases.push(this.training_value);
      this.training_value = '';
    },
    deleteTraining(i) {
      this.training_phrases.splice(i, 1);
    },
    addWebhook() {
      if(!this.webhook_value.trim()) return;
      this.text_response.push(this.webhook_value);
      this.webhook_value = '';
    },
    deleteWebhook(i) {
      this.text_response.splice(i, 1);
    },
    addOutput() {
      if(!this.output_value.trim() || !this.span_value.trim()) return;
      this.output_context.push({ name: this.output_value, lifespan: this.span_value });
      this.output_value = '';
      this.span_value = '';
    },
    deleteOutput(i) {
      this.output_context.splice(i, 1);
    },
    clearForm() {
      this.input_context = [];
      this.training_phrases = [];
      this.text_response = [];
      this.output_context = [];
      this.input_value = '';
      this.training_value = '';
      this.webhook_value = '';
      this.output_value = '';
      this.span_value = '';
      this.generated_intent_name = '';
      this.intent_category_name = '';
      this.new_conversation_detail = '';
      this.language = null;
      this.text = '';
      this.optionalData.category = null;
      this.optionalData.intent = null;
    },
    save() {
      this.array_errors = {
        text_response: false,
        text_error: '',
        training_phrases: false,
        training_error: ''
      }

      const text_response_error = this.text_response.length === 0;
      const training_phrases_error = this.training_phrases.length === 0;
      if(text_response_error || training_phrases_error) {
        this.array_errors = {
          text_response: text_response_error ,
          text_error: text_response_error ? 'this field is empty' : '',
          training_phrases: training_phrases_error,
          training_error: training_phrases_error ? 'this field is empty' : '',
        }

        return;
      }

      if (!this.validator.passes()) return
      this.setLoading(true)

      const name_parent = this.optionalData.intent.intent_name !== 'new_conversation_detail' ? this.optionalData.intent.intent_name_parent : this.generated_intent_name;
      const suggestion = this.optionalData.intent.intent_name !== 'new_conversation_detail' ? this.intent_category_name.charAt(0).toUpperCase() + this.intent_category_name.slice(1) : this.new_conversation_detail;


      this.$dhDataProvider
        .create('addIntent', {
          data: {
            intent_name: this.generated_intent_name,
            intent_category_name: this.optionalData.category.description,
            intent_description: this.text,
            suggestion_text: suggestion,
            intent_name_parent: name_parent,
            input_context: this.input_context.filter((resp) => Boolean(resp.trim())),
            output_context: this.output_context.filter((resp) => Boolean(resp.name.trim())),
            training_phrases: this.training_phrases.filter((resp) => Boolean(resp.trim())),
            text_response: this.text_response.filter((resp) => Boolean(resp.trim())),
            language: this.language.value
          },
        })
        .then(() => {
          if (!this.category || !this.intent) {
            this.setCategory(this.optionalData.category)
            if(this.optionalData.intent.intent_name !== 'new_conversation_detail') {
              this.setIntent(this.optionalData.intent)
            }
          }
          this.notifySuccess('The intent was created successfully')
        })
        .catch(error => this.notifyError(error.message))
        .finally(() => {
          this.clearForm();
          this.fetchCategories();
          this.setLoading(false)
        })
    },
  },
  validations: {
    text: 'required | is_empty',
    'optionalData.category': 'required',
    'optionalData.intent': 'required',
    language: 'required',
  },
  created() {
    this.initValidator()
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'text.is_empty': 'this field is empty.',
    })
    this.fetchCategories()
  },
}
</script>
<style scoped>
.isDisabled {
  @apply text-gray-400 !important;
}
.element {
    @apply w-full font-400 h-10 text-base;
}
.bordered {
  @apply border border-gray-300 rounded-xs outline-none py-2 px-3;
}
.container-element {
  max-height: 10rem;
  overflow: auto;
}
</style>
